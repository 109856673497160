import fetch from 'auth/GenericFetchInterceptor'

const DashboardService = {}


/* DASHBOARD SCADENZE PAZIENTI*/
DashboardService.getVisiteInScadenza = function (dataInizio, dataFine, puntiVendita) {
    const queryStringArray = puntiVendita.map(store => `&filters[patient][stores][id][$in]=${store.id}`);
    return fetch({
        url: `/api/visits?fields[0]=data_visita&fields[1]=is_hidden&filters[is_hidden][$null][$nei]=true&filters[data_visita][$lte]=${dataFine}&filters[data_visita][$gte]=${dataInizio}&populate[patient][fields][0]=nome&populate[patient][fields][1]=cognome&filters[patient][tipo_paziente][$ne]=DECEDUTO&populate[patient][populate][0]=visits${queryStringArray.join('')}`,
        method: 'GET'
    })

}
DashboardService.getScadenzeGaranzie = function (dataInizio, dataFine, puntiVendita) {
    const queryStringArray = puntiVendita.map(store => `&filters[stores][id][$in]=${store.id}`);
    return fetch({
        url: `/api/patients?fields[0]=nome&fields[1]=cognome&fields[2]=scadenza_garanzia&filters[scadenza_garanzia][$gte]=${dataInizio}&filters[scadenza_garanzia][$lte]=${dataFine}${queryStringArray.join('')}`,
        method: 'GET'
    })

}
DashboardService.getEsamiInScadenza = function (puntiVendita) {
    return fetch({
        url: `/api/audiomedical/esami-in-scadenza`,
        method: 'POST',
        data: {puntiVendita: puntiVendita}
    })
}

/*DASHBOARD STATISTICHE*/
DashboardService.getStatistichePazienti = function (puntiVendita, annoDiVendita,type) {
    return fetch({
        url: `/api/audiomedical/statistiche-paziente`,
        method: 'POST',
        data: {puntiVendita: puntiVendita, annoDiVendita: annoDiVendita,type:type}
    })
}
DashboardService.getStatisticheProvenienze = function (puntiVendita, annoDiVendita,type) {
    return fetch({
        url: `/api/audiomedical/statistiche-provenienze`,
        method: 'POST',
        data: {puntiVendita: puntiVendita, annoDiVendita: annoDiVendita,type:type}
    })
}
DashboardService.hideVisitsService = function (data, id) {
    return fetch({
        url: `/api/visits/${id}`,
        method: 'PUT',
        data: data
    })
}
DashboardService.countVisits = function (puntiVendita) {
    return fetch({
        url: `/api/audiomedical/count-visits`,
        method: 'POST',
        data: {puntiVendita: puntiVendita}
    })
}
/* ######################## SEZIONE SCADENZE VENDITE ######################## */

DashboardService.getRateInScadenza = function (dataInizio, dataFine, puntiVendita) {
    const puntiVenditaFilters = puntiVendita.map(store => `&filters[purchase][store][documentId]=${store.documentId}`);
    return fetch({
        url: `/api/sales-histories?filters[scadenza_rata][$lte]=${dataFine}&filters[scadenza_rata][$gte]=${dataInizio}&filters[rata_saldata]=false&populate[purchase][populate][patient][fields][0]=nome&populate[purchase][populate][patient][fields][1]=cognome${puntiVenditaFilters.join('')}`,
        method: 'GET'
    })

}
DashboardService.getRateScadute = function (dataInizio, puntiVendita) {
    const puntiVenditaFilters = puntiVendita.map(store => `&filters[purchase][store][documentId]=${store.documentId}`);
    return fetch({
        url: `/api/sales-histories?filters[scadenza_rata][$lt]=${dataInizio}&filters[rata_saldata]=false&populate[purchase][populate][patient][fields][0]=nome&populate[purchase][populate][patient][fields][1]=cognome${puntiVenditaFilters.join('')}`,
        method: 'GET'
    })

}

/* ######################## SEZIONE VENDITE #############################*/


DashboardService.getSaleHistorySaldati = function (store, data_inzio, data_fine) {
    return fetch({
        url: `/api/sales-histories?filters[scadenza_rata][$lte]=${data_fine}&filters[scadenza_rata][$gte]=${data_inzio}&filters[purchase][store][documentId]=${store}&filters[rata_saldata]=true`,
        method: 'GET',
    })
}

DashboardService.getPurchaseFromTo = function (store, data_inzio, data_fine) {
    return fetch({
        url: `/api/purchases?filters[data_vendita][$lte]=${data_fine}&filters[data_vendita][$gte]=${data_inzio}&filters[store][documentId]=${store}`,
        method: 'GET',
    })
}

/* FINANZIAMENTI */
DashboardService.getActivePurchase = function (puntiVendita) {
    const puntiVenditaFilters = puntiVendita.map(store => `&filters[store][documentId]=${store.documentId}`);
    return fetch({
        url: `/api/purchases?filters[residuo][$gt]=0&populate[0]=store&populate[1]=patient&populate[2]=sales_histories${puntiVenditaFilters.join('')}`,
        method: 'GET',
    })
}


/*UTILITA VENDITE*/
DashboardService.getPazientiInPagamento = function (puntiVendita) {
    const queryStringArray = puntiVendita.map(store => `&filters[stores][documentId][$in]=${store.documentId}`);
    return fetch({
        url: `/api/patients?fields[0]=nome&fields[1]=cognome&fields[2]=stato_paziente&fields[3]=tipo_paziente&filters[stato_paziente][$contains]=IN PAGAMENTO&populate=purchases&filters[purchases][$notNull]=false${queryStringArray.join('')}`,
        method: 'GET'
    })
}
export default DashboardService;